import studee from '../media/projects/studee.png'
import matlab from '../media/projects/matlab.png'
import c_plus_plus from '../media/projects/c++.png'
import stock from '../media/projects/stock.png'
import job from '../media/projects/job.png'
import aws from '../media/projects/aws.png'
import ar from '../media/projects/ar.png'

export const projects = [
    {
        name: "Studee",
        description: "Studee is a student portal application that helps students and teachers communicate both virtually and physically by keeping students ENGAGE-ed to use the platform with the help of a Credit-based system.",
        src: studee,
        link: "https://studee.ishitadatta.com"
    },
    {
        name: "Mini C++ Compiler",
        description: "This project for a Mini Compiler for the C++ programming language was made for the course title Compiler Design. The project focuses on generating an intermediate code for the language for specific constructs. It works for constructs such as conditional statements, loops, and the ternary operator. The main functionality of the project is to generate an optimized intermediate code for the given C++ source code.",
        src: c_plus_plus,
        link: "https://github.com/ishitadatta/mini-cpp-compiler"
    },
    {
        name: "S&P Stock Analysis",
        description: "This repository consists of the various models used to train and test the S&P 500 dataset to analyze and predict stocks for the course Data Analytics (UE18CS312).",
        src: stock,
        link: "https://github.com/ishitadatta/S-P-stock-analysis"
    },
    {
        name: "Eyeball Detection with MATLAB",
        description: "Eye detection and tracking has been an active research field in the past years as it adds convenience to a variety of applications. Eye-gaze tracking is been considered as untraditional method of Human Computer Interaction. Eye tracking is considered as the easiest alternative interface methods. For eye tracking and eye detection several different approaches have been proposed and used to implement different algorithms for these technologies. Generally, an eye tracking and detection system can be divided into four steps: Face detection, eye region detection, pupil detection and eye tracking.",
        src: matlab,
        link: "https://github.com/ishitadatta/EyeballDetection"
    },
    {
        name: "Odd Job Finder",
        description: "An odd job finder is a platform independent flutter app that helps you hire people for odd jobs at home as well as apply for the same. There is a vast requirement for housekeeping services. There is no concrete method in acquiring such help other than agencies that are very expensive and do not seem to work very well. This app helps connect an individual to find odd job oppurtunites or even house keeping services located near them. The duration is flexible and the requirements are chosen by the job seeker. This app will help people get the required jobs done at a convenient method. It helps people on the move to get small jobs as well conveniently.",
        src: job,
        link: "https://github.com/ishitadatta/OddJobFinder"
    }
]

export const ongoingProjects = [
    {
        name: "Onboarding SWEs using Gen AI - Industry collaboration with AWS",
        description: "As part of this project we are conducting extensive user research to understand gaps in existing onboarding software.",
        src: aws,
    },
    {
        name: "Augmented reality-guided safety navigation system",
        description: "We are building an AR system that helps residents of Midtown Atlanta navigate from one point in midtown to another point based on factors that are recommended based on street lighting, frequent footfall, pedestrian traffic, time of day and other factors.",
        src: ar
    }
]