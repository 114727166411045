import React from 'react';
import {publications} from "../content/publications";
import clothing1 from '../media/clothing-1.png';
import clothing2 from '../media/clothing-2.png';
import clothing3 from '../media/clothing-3.png';
import cba from '../media/cba.png';


const Research = () => {
    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    Digging Deeper
                                </span> into intricate concepts
                            </h1>
                        </header>
                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                    <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                        Current Research
                                    </span>
                                </div>
                                <div className={"grid grid-cols-2 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                            I am currently exploring implicit and explicit techniques in clothed-human bodies.
                                            As part of this research we experimented with the SMPL model and also made use of
                                            NeRFs to tackle softbody simulation occlusion effects.
                                        </p>
                                        <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                            Using generative adversarial networks for extracting clothing from 2D images, we
                                            implemented edge detection, color masking, image segmentation, depth estimation,
                                            image inpainting diffusion models.
                                        </p>
                                    </div>
                                    <div className={"gap-2 col-span-1 grid grid-cols-3 items-center"}>
                                        <img src={clothing1} className={"col-span-1"} alt=""/>
                                        <img src={clothing2} className={"col-span-1"} alt=""/>
                                        <img src={clothing3} className={"col-span-1"} alt=""/>
                                    </div>
                                </div>
                                <div className={"grid grid-cols-4 gap-8 items-center"}>
                                    <div className={"gap-2 col-span-1"}>
                                        <img src={cba} alt=""/>
                                    </div>
                                    <div className={"flex flex-col gap-8 col-span-3"}>
                                        <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                            As part of the <i><a href={"https://cba.gatech.edu"}>Computational Behavior Analysis</a></i> lab at Georgia Tech, I am
                                            working on experimenting with implicit and explicit techniques used in
                                            clothing simulations with the objecting of improving human activity
                                            recognition accuracy and performance.
                                        </p>
                                        <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                            The key areas of focus include model performance, the suitability of these
                                            approaches for real-time applications, and the challenges presented by
                                            clothing variability, occlusions, and body pose changes.
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                    <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                        Publications
                                    </span>
                                </div>
                                <p className="-mt-6 text-base text-zinc-600 dark:text-zinc-400">
                                    I love structure. Papers and journals provide the perfect audience and academic bandwidth to put out thoughts, discoveries and experimental results out into the world.
                                </p>
                                <div className="md:border-l md:border-zinc-100 md:pl-6 md:dark:border-zinc-700/40">
                                    <div className="flex max-w-3xl flex-col space-y-16">
                                        {publications.map((pub, i) => {
                                            return (
                                                <article key={i} className="md:grid md:grid-cols-4 md:items-baseline">
                                                    <div className="md:col-span-3 group relative flex flex-col items-start">
                                                        <h2 className="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                                                            <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"/>
                                                            <a href={pub.link}>
                                                                <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"/>
                                                                <span className="relative z-10">
                                                                    {pub.name}
                                                                </span>
                                                            </a>
                                                        </h2>
                                                        <time className="md:hidden relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500 pl-3.5" dateTime={pub.date}>
                                                            <span className="absolute inset-y-0 left-0 flex items-center" aria-hidden="true">
                                                                <span className="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500"/>
                                                            </span>
                                                            {pub.dateName}
                                                        </time>
                                                        <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                                                            {pub.description}
                                                        </p>
                                                        <div aria-hidden="true" className="relative z-10 mt-4 flex items-center text-sm font-medium text-purple-400">
                                                            Read paper
                                                            <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="ml-1 h-4 w-4 stroke-current">
                                                                <path d="M6.75 5.75 9.25 8l-2.5 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <time className="mt-1 hidden md:block relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500" dateTime="2022-09-05">
                                                        {pub.dateName}
                                                    </time>
                                                </article>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Research;